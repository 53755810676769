import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router'
import t from '../../../../shared/translations'
import { Row } from '../../../../shared/components/V2/Layout'
import { claimStatus } from '../../../../shared/constants'
import {
    tablet,
    mobile,
} from '../../../../shared/components/helpers/styled_queries'
import { ArrivalPlaneIcon, DeparturePlaneIcon } from './icons'

const BoxHeader = styled.div`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: solid 1px #cbd3df;
    border-bottom: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 24px 8px;
    width: 100%;
    background-color: ${({ color }) => color};

    & .header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-around;

        & .header-col {
            width: 100%;

            & h2 {
                color: #fff;
                text-align: left;
                font-family: Open Sans;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 18.62px;
                margin-bottom: 8px;
            }

            & .header-subtitle {
                display: flex;
                align-items: center;
                margin-bottom: 4px;

                svg {
                    margin-right: 8px;
                }

                h3 {
                    width: 220px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #fff;
                    margin: 0;
                    font-family: Open Sans;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
        }

        & .header-icon {
            svg {
                width: 42px;
                height: 42px;
            }
        }
    }
`

const BoxContent = styled.div`
    border: solid 1px #cbd3df;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    width: 100%;
    height: 100%;

    & .airline {
        display: flex;
        justify-content: space-between;
        width: 100%;

        & :first-child {
            margin-right: 8px;
        }

        p {
            color: #7f8fa4;
            text-align: center;
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            margin-bottom: 4px;
        }
    }

    & .reason {
        margin-bottom: 16px;
        color: #7f8fa4;
        text-align: center;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
    }

    .bold {
        font-weight: 600;
        color: #353d51;
    }
`

const BoxLine = styled.div`
    position: relative;
    height: 23px;
    width: 100%;
    padding: 0 8px;
    overflow: hidden;
    background: ${({ color }) =>
        `linear-gradient( to bottom, ${color} 0%, ${color} 50%, white 50%, white 100% )`};

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        border: solid 1px #cbd3df;
        border-radius: 50%;
        z-index: 10;
        background: #fff;
    }

    &:before {
        border-bottom-color: transparent;
        border-left-color: transparent;
        left: -8px;
        transform: rotate(45deg);
        box-shadow: inset 0 0 10px #dee2e8;
    }

    &:after {
        border-bottom-color: transparent;
        border-right-color: transparent;
        right: -8px;
        transform: rotate(-45deg);
        box-shadow: inset 0 0 10px #dee2e8;
    }

    .dashed-line {
        position: absolute;
        z-index: 9;
        top: 8px;
        width: 100%;
        margin: 0;
    }
`

export const NewClaimBox = styled(Link)`
    position: relative;
    background-color: #ffffff;
    min-height: 343px;
    height: 100%;
    display: flex;
    border-radius: 8px;
    box-sizing: border-box;

    &:hover {
        cursor: pointer;
        margin-top: -3px;
        margin-bottom: 3px;

        ${BoxContent}, ${BoxHeader} {
            border-color: #4ccd82;
        }

        ${BoxLine} {
            &:after,
            &:before {
                border-color: #4ccd82;
            }
        }
    }

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    &.centered-content {
        align-items: center;
        justify-content: center;
    }

    &.buffered {
        opacity: 0.5;
        cursor: inherit;

        &:hover {
            border-color: #cbd3df;
            transform: unset;
        }
    }

    &.new-claim {
        border: solid 1px #cbd3df;
        border-radius: 8px;

        &:hover {
            border-color: #4ccd82;
        }
    }

    ${mobile`
        &.new-claim {
            padding: 28px;
            min-height: unset;

            &:before, &:after {
                content: none;
            }
        }
    `}

    ${tablet`
        &.new-claim {
            padding: 28px;
            min-height: unset;

            &:before, &:after {
                content: none;
            }
        }
    `}
`

const StyledLink = styled.div`
    padding: 13px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 1px solid #7f8fa4;
    background: #fff;
    opacity: 0.9;
    cursor: pointer;
    color: #7f8fa4;
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    height: fit-content;
    display: none;
    margin-top: 16px;
    width: 100%;


    ${mobile`
        border: 1px solid #3A4250;
        color: #3A4250;
        `}

    ${tablet`
        display: flex;
    `}

    ${mobile`
        display: flex;
    `}
`

const ClaimBox = styled(NewClaimBox)`
    flex-direction: column;
    align-items: center;
    box-shadow: 0 13px 18px 0 #dee2e8;
    border: none;
    box-sizing: border-box;

    &:hover {
        box-shadow: 0 17px 14px 0 #dee2e8;
    }
`

export const StatusBox = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    -webkit-backdrop-filter: blur(17px);
    backdrop-filter: blur(17px);
    border: solid 1px #ffb620;
    background-color: #ffffff;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;

    .icon {
        margin: 0px 0px 16px 0px;
    }

    p {
        max-width: 100%;
        text-align: left;
        color: #7f8fa4;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.5px; /* 162.5% */

        &.title {
            color: #353d51;
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 18.62px; /* 133% */
            margin-bottom: 8px;
        }

        &.status-description {
            display: -webkit-box;
            max-width: 300px;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    &.box-status--green {
        border-color: #32b56a;
    }

    &.box-status--teal {
        border-color: #2852ad;
    }

    &.box-status--yellow {
        border-color: #ffb620;
    }

    &.box-status--red {
        border-color: #f16464;
    }
`

const ClaimItemCard = ({
    arrival,
    departure,
    to,
    reason,
    airline,
    flightNo,
    date,
    status,
    transactionStatus,
    payoutConfig,
    className,
    isFastPayout = false,
    code,
    primaryColor,
}) => {
    const transactionDescription = payoutConfig
        ? t`${transactionStatus}_description${
              isFastPayout ? '.fast_payout' : ''
          }`.replace('%period%', payoutConfig)
        : t`${transactionStatus}_description`
    const description = t`${status}_description`
    const statusClaim = transactionStatus || status
    const color =
        (claimStatus({ primaryColor })[statusClaim] || {}).color ||
        claimStatus({ primaryColor }).default.color
    const statusColor =
        (claimStatus({ primaryColor })[statusClaim] || {}).statusColor ||
        claimStatus({ primaryColor }).default.color

    return (
        <ClaimBox to={to} className={className}>
            <BoxHeader color={color}>
                <Row className="header">
                    <div className="header-col">
                        {code && <h2># {code}</h2>}
                        <div title={`${departure}`} className="header-subtitle">
                            <DeparturePlaneIcon />
                            <h3>{departure}</h3>
                        </div>
                        <div title={`${arrival}`} className="header-subtitle">
                            <ArrivalPlaneIcon />
                            <h3>{arrival}</h3>
                        </div>
                    </div>
                    <div className="header-icon">
                        {claimStatus({ primaryColor })[statusClaim]
                            ? (
                                  claimStatus({ primaryColor })[statusClaim] ||
                                  {}
                              ).icon()
                            : claimStatus({ primaryColor }).default.icon()}
                    </div>
                </Row>
            </BoxHeader>
            <BoxLine color={color}>
                <hr className="dashed-line" />
            </BoxLine>
            <BoxContent>
                <div className="airline">
                    <p>
                        <span className="bold">{airline}</span>{' '}
                        {`(${flightNo})`}
                    </p>
                    <p>{date}</p>
                </div>
                <p className="reason">{reason}</p>

                <StatusBox className={`box-status--${statusColor}`}>
                    <p className="title">{t`${transactionStatus || status}`}</p>
                    <p className="status-description">
                        {transactionStatus
                            ? transactionDescription
                            : description}
                    </p>
                </StatusBox>
                <StyledLink>{t`claim.item.card.details.btn`}</StyledLink>
            </BoxContent>
        </ClaimBox>
    )
}

export default ClaimItemCard
