import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import t from '../../../translations'
import NavLogo from './NavLogo'
import NavMenu from './NavMenu'
import Dropdown from './Dropdown'
import ToggleButton from './ToggleButton'
import NavUserButton from './NavUserButton'
import {
    tablet,
    mobile,
} from '../../../../shared/components/helpers/styled_queries'
import { ReferalShortLink } from '../../../../views/V2/components/ReferalLink'
import { paths } from '../../../constants'

const NavContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    flex: 1;
    justify-content: space-between;

    @media (min-width: 990px) {
        max-width: 119rem;
    }

    @media (max-width: 1200px) {
        padding: 0px 16px;
    }

    @media (max-width: 990px) {
        padding: 15px;
    }
`

const StyledLink = styled(Link)`
    padding: 13px 32px;
    justify-content: center;
    align-items: center;
    border-radius: ${({ signatureConfigs }) =>
        (signatureConfigs && signatureConfigs.claimButtonRadius) || '50px;'}
    color: ${({ theme }) =>
        theme.colors.headerTextColorSecondary ? theme.colors.headerTextColorSecondary :  theme.colors.primaryColor} !important;
    border: 1px solid ${({ theme }) =>
        theme.colors.headerSecondaryColor || theme.colors.primaryColor};
    background: ${({ signatureConfigs, theme }) =>
        (signatureConfigs
            ? signatureConfigs.claimButtonColor
            : theme.colors.headerSecondaryColor) || '#fff'};
    opacity: 0.9;
    cursor: pointer;
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    height: fit-content;
    display: flex;
    margin-right: 24px;
    white-space: nowrap;

    ${tablet`
        display: none;
    `}

    ${mobile`
        display: none;
    `}
`

const ReferralWrapper = styled.div`
    display: flex;
    justify-content: end;
`

const ToggleContainer = styled.div`
    position: relative;
`

const Navigation = ({
    resetV2Claim,
    addNewClaimText,
    onAddNewClaimClick,
    logoLink,
    location,
    closeMenu,
    saveUserClaim,
    logout,
    renderName,
    nickname,
    resetAgreement,
    showMenu,
    active,
    navLinks,
    getUserData,
    signatureConfigs,
    handleReferralClick,
}) => (
    <NavContainer id="pageTopNavbar">
        <NavLogo signatureConfigs={signatureConfigs} logoLink={logoLink} />
        <NavMenu navLinks={navLinks} signatureConfigs={signatureConfigs} />
        {(location.pathname === paths.CLAIMS ||
            location.pathname === paths.PLANNED_TRIPS ||
            location.pathname === paths.SUBSCRIPTIONS) && (
            <ReferralWrapper>
                <ReferalShortLink
                    linkText={t`referal.text.share_and_earn`}
                    handleReferralClick={handleReferralClick}
                />
            </ReferralWrapper>
        )}

        <StyledLink
            style={{
                color: signatureConfigs && signatureConfigs.claimTextColor,
            }}
            signatureConfigs={signatureConfigs}
            onClick={onAddNewClaimClick}
        >
            {addNewClaimText}
        </StyledLink>
        <ToggleContainer>
            <NavUserButton
                signatureConfigs={signatureConfigs}
                nickname={nickname}
                active={active}
                showMenu={showMenu}
            />
            <ToggleButton active={active} showMenu={showMenu} />
            {active && (
                <Dropdown
                    signatureConfigs={signatureConfigs}
                    nickname={nickname}
                    renderName={renderName}
                    saveUserClaim={saveUserClaim}
                    logout={logout}
                    resetAgreement={resetAgreement}
                    resetV2Claim={resetV2Claim}
                    location={location}
                    closeMenu={closeMenu}
                    getUserData={getUserData}
                    handleReferralClick={handleReferralClick}
                />
            )}
        </ToggleContainer>
    </NavContainer>
)

Navigation.propTypes = {
    saveUserClaim: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    renderName: PropTypes.func.isRequired,
    resetAgreement: PropTypes.func.isRequired,
    showMenu: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    navLinks: PropTypes.arrayOf(PropTypes.shape()),
    logoLink: PropTypes.string.isRequired,
}

export default Navigation
